
.share-toolbar {
  padding: 1em;
  border-style:none;
  border-width: 1px;
  border-color: green;
}

.vcms_icon
{
  margin-left:1em;
}

/* .ssk-sticky .ssk-left { top: 100px; }  */