
.ckimage_blogpic {
  margin: 0.5em;
  padding: 0.5em;
}

.ckimage_blogpic img {
  border-radius: 5px;
  float: none;
  width: 300px;
  height: auto;
  border: thin rgb(172, 171, 171) solid;  
}

figcaption 
{
  font-family: Arial, Helvetica, sans-serif;
  font-size: .75em;
  font-style: italic;
}

.webuser_fonts
{
    font-family: Arial, Helvetica, Sans-serif;
    font-size: .9em;
}

.divtitle
{
	text-align: center;
	margin-top: 0px;
	border-bottom:1px;
	border-color: #eeeeee;
	border-style: none;	
	color: #555555;
	padding-top:0px;
	padding-left:3px;
	padding-right:3px;
	padding-bottom:2px;	
}



.vcms-popup-link { 

	text-align:left;
	color:green;
	font-weight: bold;
	letter-spacing: .01ch;
}

.vcms_navitem{
  color:black
}

.footer_style {
  font-size: .8em;	
	font-weight: bold;
	color: #333333;
  font-family: verdana, arial, helvetica;
	border-color: #cccccc;	
	border-style: solid;
	border-width: 1px 0 0 0;
	
	margin-top: 20px;
	margin-left: 20px;
		
	margin-bottom: 100px;
}	

.frontpage_section_header
{
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	font-weight: bold;
	font-size: 1.3em;
	letter-spacing: .1ch;
	text-align: center;
	border-bottom: 1px;
	border-bottom-style: solid;
	border-bottom-color: #555555;	
	padding:.2em;
	padding-bottom:1em;
	margin-bottom:1em;
}	

.donate_link
{
	font-size:1.1em;
	color: rgb(39, 156, 39);
	font-family: Arial, helvetica, Sans-serif;
	padding:4px;
	border-style: solid;
	border-radius: 5px;
	border-width: 1px;
	letter-spacing: .3ch;
	background: transparent;
}

.vcms_userforms
{
	font-family: Arial, Helvetica, sans-serif;
}

.fixedElement {
	background-color: #ffffff;
	position:fixed;
	top:0;
	width:100%;
	z-index:100;
}

.ck_media { 
	
}
