@media screen and (max-width: 990px) {
	
	.no-small-screen
	{
		display: block;
	}
	.no-wide-screen
	{
		display: none;
	}
}

@media screen and (max-width: 800px)
{
	.no-small-screen
	{
		display: none;
	}
	.no-wide-screen
	{
		display: block;
	}
}

@media screen and (min-width: 801px)
{
	.no-small-screen
	{
		display: block;
	}
	.no-wide-screen
	{
		display: none;
	}
}

@media print
{
	a:link:after, a:visited:after {
		content: "";
	}

	.no-print, .no-print *
    {
        display: none !important;
    }
}

@media print {
	a[href]:after {
	  content: none !important;
	}
}

.testsystem
{
 display: none;
}


.vcms_divcenter
{
	display:block;
	margin-left:auto;
	margin-right:auto;
}

.vcmsuser_forms 
{
	font-family: helvetica, arial, sans-serif;
	font-weight: normal;	
}

.vcms_div_line
{
	border-color:lightgray;
	border-style:none none solid none;
	border-width:1px;
	margin-top:1em;
	margin-left: 0;
	margin-bottom:1em;	
	width:100%;
}

.vcms_aboutpage
{
	font-size: 1.1em;
	font-family: Georgia, 'Times New Roman', Times, serif;
	line-height: 1.6;
}

#bsmenu_div
{
	font-size: .9em;
}

.vcms_atitle
{
	font-size: 1.1em;
	font-family: Georgia, 'Times New Roman', Times, serif;
}

.vcms_subtitle
{
	font-style:italic;
	margin-top:1em;
}

.vcms_content_jlink
{
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
	font-size:.8em; 
	font-weight: bold; 
	color:maroon
}

.vcms_fp_events
{
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	margin-left:0;
	padding-left:0;

	border-width: 1px 1px 1px 1px;	
	border-color: red;
}
.vcms_fp_events ul
{
	padding-left:.5em;
}
.vcms_fp_events li
{
	font-family: Georgia, 'Times New Roman', Times, serif;
	font-size: .9em;
	margin-top:1em;
	padding-bottom: .4em;
	
	border-style: solid;
	border-color: lightgray;
	border-width: 0 0 1px 0;	
}

.vcms_fp_events a
{
	display: block;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size:1.1em;
	font-weight: normal;
	margin-top: 1em;	
	margin-bottom: .4em;
	color: maroon;
}

.vcms_sans
{
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.quicktoc
{
	padding: .5em;
	margin-top: 1em;
	font-size:.8em;
	font-family: "Roboto";
}

.quicktoc button
{
	margin-left: 2em;
	height:2em;
	width: 10em;
}

.jtreeclass
{
    font-family: 'Roboto';
    font-size: 1em;
}

.book_icon
{    
    font-size:2em;
}

.chapter_class
{

}
.article_class
{
}

.jstree-anchor {
    
    white-space : normal !important;
    
    height : auto !important;
    
    padding-right : 24px;
}

.article_item
{
    margin-bottom:1.5em;
    border-style: dotted;
    border-width: 0 0 1px 0;
    border-color: gray;
    font-size: .8em;
}       

.showb
{
    border-width:2px;
    border-color: #ff0000;
}

h3
{
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size:1.1em;
	padding-bottom: 1em;
}

.txt_align_right
{
	text-align: right;
}

.logo-img
{
	height:80px;
	margin:20px;
	text-align: left;
	width:300px;	
}

.content-sidebar {
    display: block;
    float: right;
    width: 230px;   
    border: 1px none;
    border-color:#eeeeee;
    background-color:#efefef;    
    padding:10px;
}

#web_bc
{
	font-size:.7em;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	margin-bottom: .1em;
}

#content_body
{
	margin-top:.5em;
	padding-top:.2em;
	padding-left:.2em;
	padding-right:2em;
	border-width:1px;	
	border-style:dotted;
	border-color: #eeeeff;
	font-size:1.2em;
	font-kerning: auto;
	line-height: 1.7em;
	color: #000000;
	font-family: Georgia,Times,"Times New Roman",serif;
}

.error_messages
{	
	margin-top: 2em;
	border-width:1px;
	border-color: #336600;
	font-weight: bold;
	padding: .5em;
	border-style: solid;	
	font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;	
}

#full_site_page
{
	border-style:none;
	margin-left:.5em;
	padding-left: .2em;
	padding-right: 2em;	
}

.article_list_scrollable
{
	margin-bottom:3em;
	border-style:solid;
	border-color:lightgray;
	border-width:1px;
	
	height:600px;
	overflow:hidden; 
	overflow-y:scroll;
} 

#content_article_title
{
	padding-left:0em;
	line-height: 1.8em;
	font-family: Georgia, 'Times New Roman', Times, serif;
	font-size: 2em;			
}

#content_article_author
{
	padding-left:1.5em;
	line-height: 1.7em;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 1.2em;			
}

#main_search_div
{
	margin-left:20px;
    margin-bottom:5em;
}

.article_title  
{
	text-decoration:none
}

.div_footer 
{
	font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size:.8em; 
}

a:hover {text-decoration:underline}
 
div.horizontalRule {
    clear:both;
    width:100%;
    background-color:#d1d1d1;
    height:1px;
    margin-top:5px;
    margin-bottom:5px;
}



.menu_middle {
	font-size: 1.3vw;
	border-style: solid none solid none;
	border-width: 1px;
	padding-top: 3px;
	border-color: #cccccc;	
	text-align: center;
	font-family: Georgia;
	margin-left:1em;
	width:100%;
}

.editorial_div
{
	border-style:none;
	border-width: 1px;
	border-color: #eeeeee;
	
	padding-right:2em;
	padding-top:5px;
	padding-left:5px;
	margin-top:20px;
	color: #4A3701;
	font-size: 1.2em;
	font-family: Times New Roman;
}

.mpg_entry 
{
	font-family: "Georgia;Times New Roman;Times;";
	font-size:12px;
	text-decoration:none;
	margin-left:4px;
	margin-right:6px;
	margin-bottom:6px;	
}


.left_column
{	
	border-style: dotted;
	border-width: 1px;
	border-color: green;
	margin-right:5px;
	margin-top:4px;	
	width:200px;
}

.toolbar_div
{	
	border-style: none none dotted none;
	border-color: grey;
	border-width: 1px;
	background-color: white;
	
	position:relative;
	width:700px;
	font-family: "Verdana";
	font-size:10px;	
	color:darkblue;	
	height: 28px;	
}

.toolbar_button
{
	border-color:#eecc99;
	color:BLACK;
	font-weight:bold;
	background-color:#eecc99;
	width:42px;
	height:20px;
	font-size:10px;
	vertical-align: top;
}
.toolbar_box
{
	font-family: Tahoma;
	font-size:10px;
	font-weight: normal;
	vertical-align: middle;	
}

.toolbar_label
{
	font-family: Tahoma;
	font-size:10px;
	font-weight: normal;
	vertical-align: top;	
}

.cmstools
{
	border-style: solid;
	border-color: green;
	border-width: 1px;
	width: 600px;
	font-size:12px;
	margin-bottom: 2em;
}

.toolbar_group
{	
	border: 1px none red;
	margin-left: 1px;
	margin-top: 1px;
	
	position: relative;
	top:0;
	font-family: Tahoma;
	font-size:10px;
	font-weight: normal;
	vertical-align: middle;
	color: darkblue;	
}

.animatedText
{
	font-size: 2em;
	font-weight: lighter;
	font-family: cursive;
}

.column_box
{	
	border-style: dotted;
	border-color: green;
	border-width: 1px;
	margin-right:5px;
	margin-top:4px;	
	width:160px;
	
}

.right_column
{	
	border-style: dotted;
	border-width: 1px;
	border-color: green;	
	margin-left:1px;
	margin-right:0px;
	margin-top:4px;	
	
	width:200px;
}

.center_column
{
	margin-left:3px;
	margin-right:5px;
	margin-top:5px;
	margin-bottom:3px;
	
	border-style: dotted;
	border-width:0px;		
}

.bookcover_review
{
	width:90px;
	height:110px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.article_text
{
	margin-left:3px;
	margin-right:15px;
	margin-top:5px;
	margin-bottom:3px;
	
	border-style: dotted;
	border-width:0px;		
	font-size:12px;
	text-decoration:none;
}

.current_cover
{
	margin-left:10px;
	margin-top:10px;
	margin-bottom:3px;
	
	border-style: solid;
	border-width:0px;	
}

.search_div
{
	margin-right:3px;
	margin-top:10px;		
}

.search_result_list
{
	list-style-type: circle;	
	line-height: 3em;	
}

.cover_title
{	
	color: black;
	width:300px;
	font-family: Georgia, Palatino,Cambria,"Droid Serif",Georgia,"Times New Roman",Times,serif;
	font-variant: small-caps;
	text-decoration:none;
	font-size:1.5em;	
	margin-bottom:10px;
	margin-left:5px;
	margin-top:5px;
	position: relative;
	left:0px;
}

.journal_title
{	
	color: black;
	font-family: Georgia,"Times New Roman",Times,serif;	
	text-decoration:none;
	font-size:.7em;	
	font-weight: bold;	
	line-height: 1.5em;
	margin-bottom: .5em;
	position: relative;
	left:0;	
}

.journal_page_title
{
	font-size:1.8em;
}

.editiondisplay_header
{
	color: lightslategray;
	font-size:1.2em;
	font-size:1.4em;
	font-weight:bold;	
	vertical-align: top;
}

.author_gallery
{
	font-family: Georgia;
}

.author_gallery_item
{
	border-style:none;	
	border-width:1px;
	float:left;
	font-family: Georgia;		
	font-size:0.8em;	
	height:130px;
	list-style:none;
	width:100px;
}

.journal_thumbnail
{
	width:120px;
	height:120px;
	border-width:0;
	border-style: none;	
	margin-right:2em;
}

.journal_thumbnail_sm
{
	width:60px;
	height:60px;
	border-width:0;
	border-style: none;
	text-align: left;
	margin-right:2em;
}


img.author_gallery_image
{
	width:90px;
	height:90px;
	border-width:0px;
	border-style: none;	
}

img.author_content_image
{
	width:180px;
	height:180px;
	border-width:0px;
	border-style: none;			    
}


img.author_image_article
{
	width:120px;	
	height:130px;	
	border-style: none;
	border-radius: 20px;
	margin-left:.5em;
	margin-right:.5em;
	margin-bottom:1em;
	border-radius: 20px;
}


.webuser_profilepic
{
	width:240px;
	
	border-radius: 20px;
}

.thinborder_entry
{
	border-style: solid;
	border-radius: 7px;
	border-width:1px;
	border-color: #eeeeee;
	padding: 10px;
}

.authletterbtn
{
	margin-left:4px;
	margin-right:4px;
	float:left;
	list-style:none;
	font-size:1.2em;	
	font-weight: lighter;
	font-family: Georgia;
}

#nav_filter
{
	font-weight: bold;
	font-style: italic;
	color: darkgreen;	
}
.cms_toolbar_button
{
	color:#ff0000;font-size:1.3em;	
}
#authbio
{
	border-style: none;
	border-width: 1px;
	padding: 1em;
	
	border-radius: 15px;    
    padding: 20px; 	
	line-height: 1.45em;	
}

.authpageimage
{
	width:240px;
	height:280px;
}


#icms_keywords
{
	background: beige;
}

#authorpage_div
{
	margin-left:1em;	
}
.div_underlined
{
	border-top-width: 0;
	border-left-width: 0;
	border-right-width: 0;
	border-bottom-width: 1px;	
	border-color: gray;
	border-style: dotted;
	
	margin-bottom: 1.5em;
	
}

#authcaption
{
	
	margin-top:0em;
	padding:.2em;
	background: lightgray;
	width: 240px;
	color: saddlebrown;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	font-size: .8em;
	font-weight: bold;
}

.authcaption
{
	
	margin-top:0em;
	padding:.2em;
	background: lightgray;
	width: 240px;
	color: saddlebrown;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	font-size: .8em;
	font-weight: bold;
}

.authcaption2
{
	font-family: Georgia, 'Times New Roman', Times, serif;
	color: rgb(0, 68,85);
	font-size: 1em;	
}


.authcaption_aboutus
{
	
	margin-top:0em;
	padding:.2em;
	background: lightgray;
	width: 110px;
	color: saddlebrown;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	font-size: .8em;
	font-weight: bold;
}


.cms_image_item
{
	height:100px;	
	width: 90px;
	padding: 1em;
}

#cms_image_preview
{
	height:300px;
	width:400px;
}

#cms_imagelist
{
	border-style: solid;
	border-width: 1px;
	list-style: none;
	width: 360px;
	height: 360px;
	
  	overflow: auto;
  	background-color: #aabbcc;
}

.cms_image_selected
{
	border-width: 3px;
	border-style: solid;
	border-color: lightblue;
}

#authnavrow
{
	border-style: solid;
	text-align: justify;
	background: #efefef;	
	padding-left: 1em;
	font-size: 1.1em;	
	border-width: 0 0 0 0;	
	line-height: 2em;
	
	margin-top: 1em;
	margin-bottom: 1em;
	letter-spacing: .15em;
	
	border-radius: 15px;    
    padding: 20px; 
}



#author_gallery_image .img-responsive


#feedback { font-size: 1em; }
#author_selectable .ui-selecting { background: #FECA40; }
#author_selectable .ui-selected { background: #F39814; color: white; }
#author_selectable { list-style-type: none; margin: 0; padding: 0; width: 450px; }
#author_selectable li { margin: 3px; padding: 1px; float: left; width: 100px; height: 120px; font-size: .7em; text-align: center; }

#author_box
{
	border-radius: 20px;
	margin-left:1em;
	margin-bottom: 1em;
}

#content-sidebar
{
	border-radius: 20px;
}

img.journal_page_cover
{
	margin-top:10px;
	margin-right:10px;
	margin-left:10px;
	border-width:1px;
	border-color:#115533;	
	width:320px;	
	height:300px;
}
		
.archives_title
{	
	color: black;
	
	font-family: "Skolar Regular", Palatino,Cambria,"Droid Serif",Georgia,"Times New Roman",Times,serif;	
	
	text-decoration:none;
	font-size:1.3em;	
	font-weight: bold;
	color: #333333;
	padding-top:10px;		
	position: relative;
	left:0px;		
}

#search_topic_div
{
	font-size:1em;	
}

.caps_title
{	
	color: black;
	width:300px;
	font-family: "Times New Roman;Times;Georgia";
	font-variant: small-caps;
	font-size: 1.5em;
	margin-bottom:10px;
	margin-left:5px;
	margin-top:5px;
	position: relative;
	left:0px;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cms_timer_icon
{
	font-size: 1.2em;
	color: red;
	padding-right:1em;
}
.infocard
{
	border-style:dotted;
	border-width: 1px;
}

#form_errors
{
	font-size:.7em;	
	margin-top:1px;
	margin-bottom:1px;	
}

.inforcard_itemlabel
{
	background-color:#eeeeee;
	color: black;
	text-align:right;
	font-family:  "Arial";
	font-size: 12px;
	font-variant: small-caps;
	font-weight:normal;

	
	border-style:none;
	border-width: 1px;	
	width:110px;		
}

.inforcard_itemtext
{	
	color:black;
	font-family:  "Times New Roman;Times;Georgia";
	font-size: 11px;	
	font-weight:normal;
	margin-left:10px;
	margin-top:2px;
	margin-bottom:3px;
	border-style:dotted;
	border-width: 1px;	
	width:400px;		
}


.infocard_memo
{	
	color:black;
	font-family:  "Times New Roman;Times;Georgia";
	font-size: 11px;	
	font-weight:normal;
	border-style:dotted;
	border-width: 1px;	
	width:450px;		
}
.inforcard
{
	color: #336600;
	
	font-family:  "Times New Roman;Times;Georgia";
	font-size: 12px;
	font-variant: small-caps;
	font-weight:bold;
	margin-top:3px;
	margin-left:3px;
	margin-right:3px;	
	margin-bottom:3px;	
	top:0;
	border-style:none; 
	border-width: 1px;			
}

.caps_title2
{	
	background-color:#DDDDBB;
	color: #336600;
	text-align:center;
	font-family:  "Times New Roman;Times;Georgia";
	font-size: 22px;
	font-variant: small-caps;

	font-weight:bold;
	margin-top:0px;
	margin-bottom:3px;

	position:relative;
	top:0;
	border-style:none none solid none;
	border-width: 2px;	
	
}

.mainpage_login
{
    color: black;
	width:300px;
	font-family: "Times New Roman;Times;Georgia";
	font-variant: small-caps;
	font-size: .8em;	
	margin-bottom:10px;
	margin-left:5px;
	margin-top:5px;
	position: relative;
	left:0;
}

.small_caps
{	
	color: black;
	width:300px;
	font-family: "Times New Roman;Times;Georgia";
	font-variant: small-caps;
	font-size: 8px;	
	margin-bottom:10px;
	margin-left:5px;
	margin-top:5px;
	position: relative;
	left:0px;
}
.section_title
{
	color: #003366;
	text-align:center;
	font-family: 'Arial Narrow Bold', sans-serif; 
	 
	font-size: 1.3em;
	font-weight:bold;
	font-variant: small-caps;
	
	background-color: #ffffea;
	
	margin-top:0px;
	margin-bottom:20px;

	border-style:none none solid none;
	border-width: 2px;
}	

.subsection_title
{
	color: #003366;
	text-align:center;
	font-family: "Times New Roman;Times;Georgia";
	font-size: 12px;
	font-variant: small-caps;
	margin-top:0px;
	margin-bottom:3px;	
}	

.sponsor_class 
{
	font-family:Verdana, Arial, Helvetica;
	font-size: 9px; 	
	
	margin-left:10px;
	margin-right:10px;	
	margin-top: 0;
	margin-bottom: 3px;
}

.verticle_colsep
{
	background-color:red;
	border-style: solid;
	border-width: 1px;
	border-color: green;			
}

.journal_list_entry { 
	
	height:200px;
	width:220px;
	border-width:1px;
	border-style:none;
	border-color: #eeeeee;
	padding-bottom:40px;	
	
	margin:10px;	
	float:left;	
		
	vertical-align: top;
	word-break: normal; 
	word-wrap: normal;
	margin-bottom: 30px;
}

.journal_list_title
{
	font-family: Georgia;
	font-size: .7em;
}

.redborder 
{
	border-width:3px;
	border-style:solid;
	border-color: #336600;
}

.doclist_item
{
	margin-bottom: 30px;	
	list-style-type: none;
	background-image: url('images/pdficon.png');	
	background-repeat: no-repeat;	
	padding-left: 0;
	text-indent:36px;	
	font-weight:normal;
	font-size: 1em;
}

.event_fp_title
{
	display:inline;	
	list-style-type: none;		
	background-repeat: no-repeat;	
	padding-left: 0;		
	font-weight:normal;
	font-size: 1em;
	
	line-height: 2em;
}


.doclist_item_rtl
{
	margin-bottom: 30px;	
	list-style-type: none;
	background-image: url('images/pdficon.png');	
	background-repeat: no-repeat;
	background-position: 100%;	
	padding-right: 0;
	text-indent:36px;	
	font-weight:normal;
	font-size: 1em;
	text-align: right;
}

.event_list_item
{
	font-family: "Skolar Regular", Palatino,Cambria,"Droid Serif",Georgia,"Times New Roman",Times,serif;
	margin-left:30px;
	margin-bottom:10px;
	list-style-type: square;	
	padding-left: 0;	
	line-height: 200%;
	font-weight: normal;
	font-size: 1em;
	
}

.toc_entry
{
	margin-bottom:1.2em;
	list-style-type: none;
	line-height: 1.5em;
}

.max-lines {
  display: block; 
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3.6em;
  line-height: 1.8em;
}

.blog_box_item
{
	border-color: gray;
	border-style: dotted;
	border-width: 1px;
	border-radius: 15px;
	
	padding: 1em;	
	margin-left:.4em;
	height: 10em;

	list-style-type: none;
	background-repeat: no-repeat;	

	font-weight: normal;
	
	font-size: 1em;	
	font-family: Georgia, 'Times New Roman', Times, serif;
}


.blog_list_item
{
	border-color: gray;
	border-style: dotted;
	border-width: 1px;
	border-radius: 20px;
	
	padding: 1em;
	
	margin-left:.4em;

	list-style-type: none;
	background-repeat: no-repeat;	

	font-weight: normal;
	
	font-size: 1em;	
	font-family: Georgia, 'Times New Roman', Times, serif;
}

#blog_auth_pic
{
	
	vertical-align: top;
	border-radius: 20px;
	margin-right: 1em;
}

.blog_auth_pic_mainpage
{
	
	vertical-align: middle;
	border-radius: 20px;
	margin-right: 1em;
	margin-bottom: 1em;
	width: 100px;
	height: 125px;
}

.blog_date
{
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	font-size: .8em;
	color: darkgreen;
}
#authorbox_img
{
	border-radius: 20px;
}

.borderdiv
{
	border-radius: 1px;
	border-width: 1px;
	border-style: solid;
}


.list_item_desc
{	
	list-style-type: square;
	background-repeat: no-repeat;	
	padding-left: 0;
	text-indent:0px;	
	font-weight: normal;
	
	font-size: .85em;	
	font-style: italic;
	color: #222222;
}

a:hover {text-decoration:underline}
A:link {text-decoration: none; color:#004455; }
A:visited {text-decoration: none; color:#330000; }
A:active {text-decoration: underline }
A:hover {text-decoration: underline; color: green;}

body
{
	font-family: Georgia,"Times New Roman",Times,serif;
	font-size: 16px;
	margin-top: 0;
	background-color: #fefefe;
}

.main_textbox {
    font-size: .83em;
    font-family: "Skolar Regular",Palatino,Cambria,"Droid Serif",Georgia,"Times New Roman",Times,serif;
	border-color: #eeeeff;	
	border-style: solid;
	border-width: 1px;
	background: #eeeeff;
	margin-left: 5px;
	margin-right: 5px;
	margin-top: 20px;
	padding: 12px;
	text-align: center;
}

.mailing_list {
    font-size: .7em;
	font-weight: bold;
    font-family: verdana, arial, helvetica;
	border-color: #ff0000;	
	margin-left: 20px;
	margin-top: 20px;
}


.mailing_submit { 
    height:25px; 
    width:150px;    
    color:#000000;	
}

.search_input_box {
	height:18px;
	width:170px;
	border-style:solid; 
	border-color: #2f536a;
	font-family: Verdana; 
	font-size: 8pt; 
	color: #2f536a; 
	font-weight: normal;
}

.journal_subtitle { 
	font-family: "Skolar Regular",Palatino,Cambria,"Droid Serif",Georgia,"Times New Roman",Times,serif;
	font-size: .9em;
	font-weight: bold;
	padding-bottom: 10px;
}

.blog_list_entry {
	
	font-family: Roboto, sans-serif;
	font-size: 1em;
	border-color: #eeeeee;	
	border-width: 1px 0 0 0;
	
	border-radius: 6px;
	margin-top: 1em;
}

ul.blog_list_entry  {
	display: table;	
}

li.blog_list_entry {
	display: table-row;
	line-height: 2.2em;	
}

li.blog_list_entry ::before {
    display: table-cell;
	text-align: right;	
}

.frontpage_blogs
{
	padding-left: 1em;
}


.doc_summary
{
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 0.9em;
	font-style: italic;
	line-height: 1em;
}


.article_list_entry {
	font-family: "Skolar Regular",Palatino,Cambria,"Droid Serif",Georgia,"Times New Roman",Times,serif;
	border-color: #bbbbbb;	
	border-width: 0 0 1px 0;
	border-style: none;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left:0px;
	font-size: .85em;
}

.left_column_cell
{
	border-color: #bbbbbb;	
	border-width: 0 1px 0 0;
	border-style: dotted;
}

.right_column_cell
{
	border-color: #bbbbbb;	
	border-width: 0 0 0 1px;
	border-style: dotted;
}

#txtlzr
{
	font-family: Georgia, 'Arial',sans-serif;
	font-size: .76em;
	height: 5px;
	margin-top: 3px;	
	background: #ffffff;
	color: #553333;
	vertical-align: top;
	padding: 3px;
	font-weight: bold;
}



div#three_header {
    clear: both;
    height: 1px;    
    padding: 1px;
}
div#three_left {
    float: left;
    width: 250px;
	padding: 15px;
}

div#three_right {
    float: right;
    width: 200px;
    background-color: green;
	padding: 15px;    
}
div#three_middle {
    padding: 0 160px 5px 160px;
    margin: 0;
    width: 450px;
	padding: 15px;    
}
div#three_footer {
    clear: both;
    background-color: yellow;
}

.vcms_forms
{
	font-family: Arial, Helvetica, sans-serif;
	line-height: 2em;	
}

.webuser_profile_toolbar
{
	border-width:1px 0 1px 0;
	border-style:solid;
	border-color: #330000;
	margin-bottom: 2em;
	padding-top: 1em;	
}

.webuser_submit_button
{
	margin-top: 1em;
	text-align: center;
	background: #aabbcc;
	padding: .5em;
	font-family: Arial, Helvetica, sans-serif;
}

#navcontainer ul
{
margin: 0;
padding: 0;
list-style-type: none;
text-align: center;
}

#navcontainer ul li { display: inline; }

#navcontainer ul li a
{
text-decoration: none;
padding: .2em 1em;
color: #fff;
background-color: #036;
}

#navcontainer ul li a:hover
{
color: #fff;
background-color: #369;
}

.usertab_label
{
	font-family: Arial, Helvetica, sans-serif;
	padding-left: 1em;
	padding-right: 1em;
	font-size: .9em;
}

.usertab_row
{
	margin-bottom: 1em;
} 

body h3
{
	margin-top:0px;
}

.spanprompt
{
	font-family: Arial, Helvetica, sans-serif;
	color:maroon;
}

.top_menu_item
{
	color: #663300;
	font-family: Verdana;
	font-size: 12px;
	font-variant: small-caps;		
	font-weight:bold;
	left:0;
	text-align:center;
	margin-top:15px;
	margin-bottom:5px;
	position:relative;
	top:0;
	border-style:dotted none none dotted;
	border-width: 1px;	
}


.menuDiv {
	margin: 10px;
	border-style: solid none solid none;
	border-width: thin;
	padding-top: 3px;
	padding-bottom: 3px;
	background-color:#eeffcc;
	font-weight:bold;
}

.headerDiv {
	margin-top: 10px;
}

#content_header
{
	
}

@media only screen and (max-width: 1026px) {
    #mid_menu_div1 {
        display: none;
    }
}

.title_desc
{
	font-family: "Georgia;Times New Roman;Times;";
	font-size: 12px; 
	font-weight: Bold;
	font-style: Normal;
	color: black;
	margin-top:5px;
}


.blog_title  
{
	text-decoration:none;
	font-family: "Georgia;Times New Roman;Times";
	font-size:12px;
}

.blog_author 
{
	text-decoration:none;
	font-family: Times New Roman; 
	font-size:11px;
}

.div_blog_entry1
{
	font-family: "Georgia;Times New Roman;Times";
	font-size: 1em;
	text-decoration:none;
	margin-left:4px;
	margin-right:6px;
	margin-bottom:6px;	
	margin-top:5px;
}
